import React from 'react';
import styled, { css } from 'styled-components';
import Carousel from '@ui/layouts/Carousel';
import Link from '@ui/actions/Link';
import baseRoutes from '@constants/routes';
import GPBanner from '~/images/banner/GPBanner.png';
import FRBanner from '~/images/banner/FRBanner.png';
import Hub2Banner from '~/images/banner/Hub2Banners.png';
import { clickedBanner } from '@utilities/analytics/analyticsEvents';

const layout = css`
    width: 800px;

    // Nuka carousel class
    .slider-slide {
        > div {
            height: 120px;
        }

        img {
            object-fit: contain;
            width: 100%;
        }
    }
`;

const Slide = ({ children }) => {
    return (
        <div role="group" aria-roledescription="slide">
            {children}
        </div>
    );
};

const HomeCarousel = ({ className }) => {
    return (
        <div className={className}>
            <Carousel label="Hub News and Features">
                <Slide>
                    <img
                        src={Hub2Banner}
                        alt="The New & Enhanced Lexile & Quantile Hub is arriving this fall!"
                    />
                </Slide>
                <Slide>
                    <img
                        src={FRBanner}
                        alt="NEW tools for K-2 Teachers! Use Find a Decodable Book and Decodable Passages to support foundational reading."
                    />
                </Slide>
                <Slide>
                    <Link
                        to={baseRoutes.GROWTH_PLANNER_LEXILE}
                        onClick={() =>
                            clickedBanner({
                                bannerLabel: 'Lexile Growth Planner'
                            })
                        }
                    >
                        <img
                            src={GPBanner}
                            alt="Use Lexile and Quantile Growth Planners to determine if your student is on track to be college and career ready."
                        />
                    </Link>
                </Slide>
            </Carousel>
        </div>
    );
};

export default styled(HomeCarousel)`
    ${layout}
`;
